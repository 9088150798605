import React from 'react';
import { Link } from 'gatsby';

export default function FourOhFour() {
  return (
    <div>
      <h1>404 Page Error</h1>
      <p>This Page does not exist</p>
      <p>
        Back to <Link to="/index.js">Home</Link>
      </p>
    </div>
  );
}
